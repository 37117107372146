
.box {
    flex-direction: column;
    align-items:center;
    justify-content:center;
    display:flex;
    margin-bottom: 11vh;
    width: 50vw;
    
}



.campo {
    flex-direction: column;
    margin-bottom: 2%;
    width: 100%;
    justify-content: space-between;
    
}

.campoDuplo {
    display: flex;
    flex-direction: row;
    gap: 3%;
    width: 100%;
    
}

